import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  json,
  LinksFunction,
  LoaderFunctionArgs,
  V2_MetaFunction,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import dayjs from "dayjs";
import stylesheet from "~/tailwind.css?url";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { Layout } from "./components/Layout";
import { GlobalLoadingIndicator } from "./components/GlobalLoadingIndicator";
import { getSession } from "./sessions";
import { authenticate } from "./utils/authenticate";

dayjs.extend(localizedFormat);
dayjs.extend(relative);
dayjs.extend(duration);

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
  { rel: "apple-touch-icon", sizes: "57x57", href: "/apple-icon-57x57.png" },
  { rel: "apple-touch-icon", sizes: "60x60", href: "/apple-icon-60x60.png" },
  { rel: "apple-touch-icon", sizes: "72x72", href: "/apple-icon-72x72.png" },
  { rel: "apple-touch-icon", sizes: "76x76", href: "/apple-icon-76x76.png" },
  {
    rel: "apple-touch-icon",
    sizes: "114x114",
    href: "/apple-icon-114x114.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "120x120",
    href: "/apple-icon-120x120.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "144x144",
    href: "/apple-icon-144x144.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "152x152",
    href: "/apple-icon-152x152.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/apple-icon-180x180.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "192x192",
    href: "/android-icon-192x192.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon-32x32.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "96x96",
    href: "/favicon-96x96.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon-16x16.png",
  },
  { rel: "manifest", href: "/manifest.json" },
];

export const meta: V2_MetaFunction = () => [
  {
    title: "Next Level Jobs EU",
  },
  {
    name: "description",
    description:
      "Get notified about new opportunities at top tech companies as soon as they are posted.",
  },
];

const privatePages = ["/jobs", "/account"];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await getSession(request.headers.get("Cookie"));
  const accessToken = session.get("accessToken");
  if (
    accessToken &&
    privatePages.some((page) => request.url.startsWith(page))
  ) {
    await authenticate(request, false);
  }

  return json({
    url: request.url,
    accessToken,
  });
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const { accessToken } = useLoaderData<typeof loader>();
  const location = useLocation();
  const wide = ["/companies", "/jobs"].some((path) =>
    location?.pathname?.startsWith(path)
  );

  const matches = useMatches();

  // If at least one route wants to hydrate, this will return true
  const disableJS = matches.some((match) => match.handle?.disableJS);

  const path = location.pathname;

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {!path.includes("scheduled-jobs") && (
          <script
            defer
            data-domain="nextleveljobs.eu"
            src="https://plausible.io/js/script.js"
          ></script>
        )}
      </head>
      <body>
        <Layout
          blurBackground={true}
          wide={wide}
          header
          center={false}
          authenticated={!!accessToken}
        >
          <GlobalLoadingIndicator />
          <Outlet />
        </Layout>
        <ScrollRestoration />
        {!path.includes("scheduled-jobs") && (
          <script
            defer
            data-domain="nextleveljobs.eu"
            src="https://plausible.io/js/script.tagged-events.js"
          ></script>
        )}
        {!disableJS && <Scripts />}
      </body>
    </html>
  );
}
